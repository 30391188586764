import Layout from "../layouts/Layout"

const Privacy = () => {

    return (
        <Layout>
            <div className="bg-[#d8d9e16f] w-full h-[10rem] md:h-[15rem] lg:h-[20rem] rounded-[4rem] flex items-center">
                <p className="w-11/12 lg:w-3/4 mx-auto text-[1.8rem] md:text-[2.5rem] lg:text-[4rem] font-bold">Privacy Privacy</p>
            </div>
            <div className="w-11/12 lg:w-3/4 mx-auto mt-[2rem] md:mt-[4rem]">
 
                <h1 className="text-2xl md:text-4xl my-6">Privacy Policy for Cloudax limited</h1>

                <p>Cloudax is accessible at the URL <a className="text-[blue]" href="https://cloudax.io.">https://cloudax.io.</a> One of our main priorities is the privacy of our visitors. This Privacy Policy document contains the types of information that is collected and recorded by Cloudax and how we use them.</p>

                <p className="my-3">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

                <p>This Privacy Policy applies only to our online activities and is valid for visitors of our websites with regard to the information that they share and/or collect in Cloudax. This policy is not applicable to any information collected offline or via channels other than Cloudax websites.</p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">Consent</h2>

                <p>By using our websites, you consent to the terms of our Privacy Policy.</p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">Information we collect</h2>

                <p>The personal information you are asked to provide, and the reasons why you are asked to provide them will be made clear to you at the point we ask you to provide your personal information.</p>
                <p className="my-3">If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the content of the message, attachment(s) and any other information you may choose to provide.</p>
                <p>When you <a className="text-[blue]" href="https://account.cloudax.finance/signup">signup or create an account</a>  on Cloudax, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                <p className="mt-3">Cloudax application would request certain permissions from users that allow it to access the user’s device data. The requested permissions are summarized within the document</p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">Cookies and Web Beacons</h2>
                <p>When you visit our site, we use cookies to store your information, especially your personal preferences. This may also come up as a pop-up when you visit, or interact with some of our products and services. </p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">Device Permissions for Personal Data Access</h2>
                <p>Cloudax application may request certain permissions that allow it to access your device data, depending on your device type.</p>
                <p className="my-3">These permissions must be granted by you before the respective information can be accessed. After permission has been given, it can be revoked by you in the device settings at any time. However, the revoking of such permissions may impact or limit the function of Cloudax application.</p>
                <p>If you grant any of the permissions listed below, the respective personal data may be processed by the Cloudax application.</p>
                <h3 className="text-lg md:text-xl mb-1 mt-3">Camera Permission</h3>
                <p>This is needed during registration and verification of your identity in your wallet. The camera permission is used for accessing the camera or photo library and video from the device.</p>
                <h3 className="text-lg md:text-xl mb-1 mt-3">Microphone Permission</h3>
                <p>This permission allows accessing and recording microphone audio from the user’s device.</p>
                <h3 className="text-lg md:text-xl mb-1 mt-3">Location Permission</h3>
                <p>This would help us to tailor our service(s) within the regulatory framework of your location. </p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">How we use your information</h2>

                <p className="mb-1">We use the information we collect in various ways, including to:</p>

                <ul className="list-disc ml-5">
                    <li >Provide, operate, and maintain our websites and applications</li>
                    <li >Improve, personalize, and expand our websites and applications</li>
                    <li >Understand and analyze how you use our websites and applications</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                    <li>Send you emails</li>
                    <li >Find and prevent fraud</li>
                    <li>Users verification and registration</li>
                </ul>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">Log Files</h2>

                <p>Cloudax follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">Advertising Partners Privacy Policies</h2>

                <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Cloudax.</p>

                <p className="my-3">Third-party ad servers or ad networks uses technologies like Cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Cloudax, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit</p>

                <p>Note that Cloudax has no access to or control over these cookies that are used by third-party advertisers.</p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">Third Party Privacy Policies</h2>

                <p>Cloudax's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

                <p className="my-3">You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

                <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                <p className="my-3">Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
                <p className="my-3">Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">GDPR Data Protection Rights</h2>

                <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following</p>
                <p className="my-3">The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete</p>
                <p className="my-3">The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
                <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions</p>
                <p className="my-3">The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
                <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                <p className="my-3">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                <h2 className="text-2xl md:text-4xl mb-4 mt-8">Children's Information</h2>

                <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

                <p className="my-3">Cloudax does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best to promptly remove such information from our records</p>
            </div>
        </Layout>
    )
}

export default Privacy