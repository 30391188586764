import phone from '../images/assets/iPhone 14 Pro.png'
import small from '../images/assets/small.png'
import big from '../images/assets//big.png'
import { motion } from 'framer-motion'
import { faApple } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

const Landing = () => {

    const velocity = [
        {
            y: -100,
            transition: { duration: 3, type: 'spring', bounce: 0.6, velocity: -200 }
        },
        {
            rotate: 3,
            y: -30,
            transition: { duration: 3, type: 'spring', bounce: 0.6, velocity: -200 }
        },
        {
            rotate: -2,
            y: -10,
            transition: { duration: 3, type: 'spring', bounce: 0.6, velocity: 200 }
        }
    ]


    return (
        <div className='relative w-full'>
            <div className="w-11/12 relative xl:w-3/4 mx-auto mt-[5rem] md:mt-[12rem] flex gap-y-[10rem] flex-wrap justify-between">
                <div className="w-full md:w-2/4">
                    <h1 className="text-[2rem] md:text-[4rem] font-[800] lg:text-[6.5rem]">
                        <span className="block leading-none">Crypto</span>
                        <span className="block leading-none">Wallet</span>
                        <span className="block leading-none bg-gradient-to-r bg-clip-text text-transparent from-[blue] via-[#b029ce] to-[#00ddff]">Simplified</span>
                    </h1>
                    <p className="lg:text-xl my-6">Cloudax makes it easy to trade, invest and protect your crypto assets..</p>
                    <div className='flex gap-3'>
                        <a href='https://account.cloudax.finance/signup' className="px-9 block py-4 bg-icon text-white bg-center bg-cover rounded-3xl">Get started</a>
                        <button className='px-6 py-3 bg-[#E4E5F7] text-[blue] rounded-full'>
                            <FontAwesomeIcon icon={faApple} />
                        </button>
                        <button className='px-6 py-3 bg-[#E4E5F7] text-[blue] rounded-full'>
                            <FontAwesomeIcon icon={faPlay} />
                        </button>
                    </div>
                </div>
                <motion.div initial={{ y: 100 }} whileInView={velocity[0]} className="relative md:w-[45%] lg:[initial]">
                    <img src={phone} className='w-full h-[39rem] lg:h-[43rem] xl:h-[45rem]' alt='phone' />
                    <motion.img initial={{ rotate: 0, y: 30 }} whileInView={velocity[1]} className="absolute top-[13rem] w-full" src={big} alt='icon' />
                    <motion.img initial={{ rotate: 0, y: 30 }} whileInView={velocity[2]} className="absolute bottom-[6rem] md:bottom-[4rem] w-full" src={small} alt='icon' />
                </motion.div>
            </div>
            <div className='absolute left-0 right-0 rounded-t-[3rem] mt-[-12rem] md:mt-[-10rem] lg:mt-[-10rem] bg-[#19171C] lg:w-[99.5%] w-full mx-auto h-[20rem] lg:h-[17rem] overflow-clip'></div>
        </div>
    )
}

export default Landing

