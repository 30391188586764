import { faApple } from "@fortawesome/free-brands-svg-icons"
import { faExchange, faFeather, faHandshake, faPlay, faRocket, faShoppingCart, faWallet } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../layouts/Layout"
import phone from '../images/assets/newImg.png'

const About = () => {

    const Card = ({ icon, h1, p1 }) => {
        return (
            <div className="bg-white p-[1rem] lg:p-[2rem] rounded-3xl md:w-[47%] lg:w-[31%]">
                <p className="font-bold text-[1.5rem] lg:text-[1.8rem] flex gap-2 mb-2 items-center">
                    <FontAwesomeIcon icon={icon} />
                    {h1}</p>
                <p className="lg:text-xl">{p1}</p>
            </div>
        )
    }

    const Small = ({ h1, p1 }) => {
        return (
            <div className="bg-[#EDEDF7] p-[1rem] w-full md:w-[47%] lg:w-[23%] rounded-3xl">
                <FontAwesomeIcon className="block mb-3 text-[blue] text-[1.8rem] " icon={faHandshake} />
                <p className="font-bold text-[1.5rem]  mb-2"> {h1}</p>
                <p className="">{p1}</p>
            </div>
        )
    }

    return (
        <Layout>
            <div className="w-fit mx-auto text-center">
                <h1 className="text-[1.8rem] md:text-[2.5rem] lg:text-[4rem] xl:text-[4.5rem] mt-[7rem] mb-[2rem] leading-none font-bold"> The worlds most secure & <span className="block">easy to use crypto wallet</span></h1>
                <div className='flex gap-3 mx-auto w-fit'>
                    <a href='https://account.cloudax.finance/signup' className="px-9 block py-4 bg-icon text-white bg-center bg-cover rounded-3xl">Get started</a>
                    <button className='px-6 py-3 bg-[#E4E5F7] text-[blue] rounded-full'>
                        <FontAwesomeIcon icon={faApple} />
                    </button>
                    <button className='px-6 py-3 bg-[#E4E5F7] text-[blue] rounded-full'>
                        <FontAwesomeIcon icon={faPlay} />
                    </button>
                </div>
            </div>
            <div className="w-full bg-index pt-1">
                <div className="w-11/12 xl:w-4/5 bg-white mx-auto mt-[6rem] rounded-[2.5rem] pt-1 pb-[1rem] px-5">
                    <h3 className="text-[1.8rem]  md:text-[2.5rem] font-bold lg:text-[4rem] mt-10 mb-3 leading-none">Cloudax Wallet</h3>
                    <p className="lg:text-xl mb-7">Buy and store your crypto and NFT in a secure wallet and easily access all of them in one place. Protect your assets with our wallet; which is trusted and recognised by millions of users who rated it as the most straightforward and easiest-to-use crypto wallet.</p>

                    <div className="flex justify-center bg-[#ededf778]">
                        <img src={phone} className=' rounded-3xl lg:w-2/4' alt='coins' />
                    </div>
                </div>
            </div>
            <div className="flex w-11/12 xl:w-4/5 mx-auto mt-[5rem] justify-between gap-y-5 flex-wrap">
                <div className="md:w-[47%] lg:w-[31%] p-[1rem] lg:p-[2rem]">
                    <p className="font-bold text-[1.5rem] mb-2 lg:text-[1.8rem]">Cloudax Ecosystem</p>
                    <p className="lg:text-xl">Cloudax is an ever-expanding and scalable decentralised ecosystem.</p>
                </div>
                <Card icon={faWallet} h1='Wallet' p1='Easy to use wallet designed to appeal to non-crypto educated users within the Cloudax ecosystem' />
                <Card icon={faShoppingCart} h1='E-Commerce' p1='Bridge between physical and digital assets. Bringing liquidity to your items tied to your cryptocurrency' />
                <Card icon={faFeather} h1='NFT Marketplace' p1='Native marketplace for the secondary exchange of Non- Fungible Tokens launched within the Cloudax ecosystem.' />
                <Card icon={faRocket} h1='Launchpad' p1='Top tier incubator for quality decentralized projects which promote, publish or offer faith based and/or wholesome utility.' />
                <Card icon={faExchange} h1='Exchange' p1='Exchange within the Cloudax ecosystem where users can trade cryptocurrencies, earn interest and transact. ' />
                <div className="bg-white p-[1rem] lg:p-[2rem] rounded-3xl w-full mt-[4rem]">
                    <FontAwesomeIcon className="block mb-3 text-[blue] text-[1.8rem] " icon={faHandshake} />
                    <p className="font-bold text-[1.5rem] lg:text-[1.8rem] mb-2"> Our Mission</p>
                    <p className="lg:text-xl">The need for ease of use and simplicity has driven us to build Cloudax. We want to assist everyone, especially people who find it difficult to key into the trending digital world of web3 and blockchain. Cloudax is a cryptocurrency platform where you can buy, sell, store and trade cryptocurrency and collect NFTs. It is built with the aim of addressing all the difficult and incomprehensible parts of blockchain and cryptocurrency in other to enable even a layman to venture into it with ease. Each of our products is built with this idea in mind - to let you understand it, so as to participate with the rest of the world. </p>
                </div>
            </div>

            <div className="bg-[#d8d9e16f] w-full mt-[5rem] rounded-[4rem] flex items-center">
                <div className="my-[4rem] w-11/12 xl:w-4/5 mx-auto lg:p-[2rem]">
                    <p className="font-bold text-[1.5rem] lg:text-[1.8rem] leading-none">Why Cloudax ?</p>

                    <div className="flex justify-between mt-[3rem] flex-wrap gap-y-6">
                        <Small h1='Simple' p1='Cloudax is simplifying the whole internet, web3 and blockchain in a way that even a 2-year-old could be able to understand these concepts.' />
                        <Small h1='Easy' p1='Cloudax is solely built for ease of use. Every aspect of our ecosystem is designed with the aim of making things easy for our users.' />
                        <Small h1='Compact' p1='Perform all your crypto activities in one place - Wallet, Exchange, NFT, Launchpad, etc. all in one place - Cloudax!' />
                        <Small h1='Secure' p1='Cloudax is built with a military-grade level of protection for maximum security.  your assets are very safe in our wallet.' />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default About