import three from '../images/assets//one.jpg'
import two from '../images/assets/two.jpg'
import one from '../images/assets/three.jpg'
import { useState } from 'react'

const Get = () => {
    const [load, setLoad] = useState(false)
    const [load1, setLoad1] = useState(false)
    const [load2, setLoad2] = useState(false)

    const loader = (e) => {
        setLoad(true)
    }

    const loader1 = (e) => {
        setLoad1(true)
    }

    const loader2 = (e) => {
        setLoad2(true)
    }

    return (
        <div className="bg-[#19171C]  py-1 w-full lg:w-[99.5%] left-0 right-0 md:rounded-b-[3rem] mx-auto text-white">
            <div className=" my-5 w-11/12 relative xl:w-[75%] py-1 mx-auto">
                <h1 className="text-[1.8rem] absolute top-[-6rem] lg:top-[-5rem] mt-[1rem] md:text-[2.5rem] font-bold lg:text-[4rem] leading-none">Get started on <span className="bg-gradient-to-r from-[#474FF4] to-[#56C2FF] bg-clip-text text-transparent block">Cloudax   <span className="text-white">in 3 easy steps</span></span>

                </h1>

                <div className="flex flex-wrap gap-y-[4rem] mt-[3rem] md:mt-[9rem] justify-between my-[4rem]">
                    <div className="w-full md:w-[45%] lg:w-[30%]">
                        <div className={`w-full bg-[#2e2e2e] rounded-3xl flex items-center justify-center overflow-hidden ${load ? null : 'h-[21rem]'}`}>
                            <img onLoad={loader} className={load ? 'block' : 'hidden'} src={one} alt='one' />
                            <div className={load ? null : 'w-[2rem] h-[2rem] animate-spin border-2 border-[#474FF4] rounded-full border-b-[#56C2FF] '}></div>
                        </div>
                        <p className="bg-gradient-to-r from-[#474FF4] to-[#56C2FF] w-fit px-5 py-3 mb-2 mt-[3rem] text-xl rounded-full">1</p>
                        <p className="lg:text-xl">Create an account</p>
                    </div>

                    <div className="w-full md:w-[45%] lg:w-[30%]">
                        <div className={`w-full bg-[#2e2e2e] rounded-3xl flex items-center justify-center overflow-hidden ${load1 ? null : 'h-[21rem]'}`}>
                            <img onLoad={loader1} className={load1 ? 'block' : 'hidden'} src={two} alt='images' />
                            <div className={load1 ? null : 'w-[2rem] h-[2rem] animate-spin border-2 border-[#474FF4] rounded-full border-b-[#56C2FF] '}></div>
                        </div>
                        <p className="bg-gradient-to-r from-[#474FF4] to-[#56C2FF] w-fit px-5 py-3 mb-2 mt-[3rem] text-xl rounded-full">2</p>
                        <p className="lg:text-xl">Link your bank account</p>
                    </div>

                    <div className="w-full md:w-[45%] lg:w-[30%]">
                        <div className={`w-full bg-[#2e2e2e] rounded-3xl flex items-center justify-center overflow-hidden ${load2 ? null : 'h-[21rem]'}`}>
                            <img onLoad={loader2} className={load2 ? 'block' : 'hidden'} src={three} alt='images' />
                            <div className={load2 ? null : 'w-[2rem] h-[2rem] animate-spin border-2 border-[#474FF4] rounded-full border-b-[#56C2FF] '}></div>
                        </div>
                        <p className="bg-gradient-to-r from-[#474FF4] to-[#56C2FF] w-fit px-5 py-3 mb-2 mt-[3rem] text-xl rounded-full">3</p>
                        <p className="lg:text-xl">Start buying and selling</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Get