import Layout from "../layouts/Layout"

const Terms = () => {

    const data = [
        {
            p1: 'Integer sollicitudin venenatis vehicula. Mauris quis orci risus. Donec eu dolor viverra, efficitur orci feugiat, mollis libero. Maecenas purus mauris, lobortis in leo sed, ultricies ultrices tellus. Aenean fringilla, ipsum eget sagittis pharetra, quam arcu dictum mauris, non consectetur sem ligula ac nisl. Sed dignissim tristique odio gravida consequat. Morbi maximus sagittis dui eu ullamcorper. Duis euismod neque ac libero auctor scelerisque. Integer viverra justo vel diam ultrices pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra Integer sollicitudin venenatis vehicula. Mauris quis orci risus. Donec eu dolor viverra, efficitur orci feugiat, mollis libero. Maecenas purus mauris, lobortis in leo sed, ultricies ultrices tellus. Aenean fringilla, ipsum eget sagittis pharetra, quam arcu dictum mauris, non consectetur sem ligula ac nisl. Sed dignissim tristique odio gravida consequat. Morbi maximus sagittis dui eu ullamcorper. Duis euismod neque ac libero auctor scelerisque. Integer viverra justo vel diam ultrices pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra'
        },
        {
            p1: 'Integer sollicitudin venenatis vehicula. Mauris quis orci risus. Donec eu dolor viverra, efficitur orci feugiat, mollis libero. Maecenas purus mauris, lobortis in leo sed, ultricies ultrices tellus. Aenean fringilla, ipsum eget sagittis pharetra, quam arcu dictum mauris, non consectetur sem ligula ac nisl. Sed dignissim tristique odio gravida consequat. Morbi maximus sagittis dui eu ullamcorper. Duis euismod neque ac libero auctor scelerisque. Integer viverra justo vel diam ultrices pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra Integer sollicitudin venenatis vehicula. Mauris quis orci risus. Donec eu dolor viverra, efficitur orci feugiat, mollis libero. Maecenas purus mauris, lobortis in leo sed, ultricies ultrices tellus. Aenean fringilla, ipsum eget sagittis pharetra, quam arcu dictum mauris, non consectetur sem ligula ac nisl. Sed dignissim tristique odio gravida consequat. Morbi maximus sagittis dui eu ullamcorper. Duis euismod neque ac libero auctor scelerisque. Integer viverra justo vel diam ultrices pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra'
        },
        {
            p1: 'Integer sollicitudin venenatis vehicula. Mauris quis orci risus. Donec eu dolor viverra, efficitur orci feugiat, mollis libero. Maecenas purus mauris, lobortis in leo sed, ultricies ultrices tellus. Aenean fringilla, ipsum eget sagittis pharetra, quam arcu dictum mauris, non consectetur sem ligula ac nisl. Sed dignissim tristique odio gravida consequat. Morbi maximus sagittis dui eu ullamcorper. Duis euismod neque ac libero auctor scelerisque. Integer viverra justo vel diam ultrices pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra Integer sollicitudin venenatis vehicula. Mauris quis orci risus. Donec eu dolor viverra, efficitur orci feugiat, mollis libero. Maecenas purus mauris, lobortis in leo sed, ultricies ultrices tellus. Aenean fringilla, ipsum eget sagittis pharetra, quam arcu dictum mauris, non consectetur sem ligula ac nisl. Sed dignissim tristique odio gravida consequat. Morbi maximus sagittis dui eu ullamcorper. Duis euismod neque ac libero auctor scelerisque. Integer viverra justo vel diam ultrices pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra'
        },
    ]

    return (
        <Layout>
            <div className="bg-[#d8d9e16f] w-full h-[10rem] md:h-[15rem] lg:h-[20rem] rounded-[4rem] flex items-center">
                <p className="w-11/12 lg:w-3/4 mx-auto text-[1.8rem] md:text-[2.5rem] lg:text-[4rem] font-bold">Terms Of Use</p>
            </div>
            <div className="w-11/12 lg:w-3/4 mx-auto mt-[2rem] md:mt-[4rem]">
            <p className="text-[1.5rem] lg:text-[1.8rem] font-bold mb-2">Lorem ipsum dolor sit?</p>
                {data.map((item, index) => <p key={index} className='mb-9'>{item.p1} </p>)}
            </div>
        </Layout>
    )
}

export default Terms