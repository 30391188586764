import Layout from "./layouts/Layout";
import Landing from "./components/Landing";
import Get from "./components/Get";
import Access from "./components/Access";
import Trusted from "./components/Trusted";

function App() {

  return (
    <Layout>
      <Landing />
      <Get />
      <Access/>
      <Trusted/>
    </Layout>
  );
}

export default App;