import large from '../images/assets/newImg.png'
import small from '../images/assets/cards.png'

const Access = () => {
    return (
        <div className="w-full bg-index py-1">
            <div className="w-11/12 xl:w-3/4 mx-auto mt-[7rem]">
                <h3 className="text-[1.8rem]  md:text-[2.5rem] font-bold lg:text-[4rem] leading-none">Achieve more with <span className="block">your crypto assets</span></h3>

                <div className="flex flex-wrap gap-y-8 justify-between my-[3rem]">
                    <div className="w-full md:w-[47%] bg-white p-4 lg:p-[1.5rem] rounded-[2.5rem]">
                        <p className="font-bold text-[1.4rem] md:text-[2rem] lg:text-[2.5rem]"> Savings account</p>
                        <p className="mb-4 mt-2">Earn daily interest on your crypto with our savings account  </p>
                        <img src={large} className=' w-full ' alt='images' />
                    </div>

                    <div className="w-full md:w-[47%] bg-white p-4 lg:p-[1.5rem] rounded-[2.5rem]">
                        <img className='w-full bg-[#ededf76b]'  src={small} alt='images' />
                        <p className="font-bold text-[1.4rem] mt-2 md:text-[2rem] lg:text-[2.5rem]">Virtual Card</p>
                        <p className="">Spend your crypto with our virtual card on Amazon, Netflix and other global merchants. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Access