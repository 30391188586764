import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faIndustry, faShieldHeart } from '@fortawesome/free-solid-svg-icons'
import icons from '../images/assets/TESTED AND RELIABLE.jpg'
import small from '../images/assets/love.png'

const Trusted = () => {
    return (
        <div className="w-full bg-index py-1">
            <div className="w-11/12 xl:w-3/4 bg-white mx-auto mt-[2rem] rounded-[2.5rem] py-1 px-5">
                <h3 className="text-[1.8rem]  md:text-[2.5rem] font-bold lg:text-[4rem] mt-10 leading-none">Trusted & reliable <span className="md:block">crypto-currency platform</span></h3>

                <div className="flex flex-wrap gap-y-8 justify-between my-[2rem]">
                    <div className="w-full md:w-[32%] bg-[#9696f61c] p-3 rounded-2xl">
                        <FontAwesomeIcon className="text-3xl text-[blue] mt-4 mb-6" icon={faShieldHeart} />
                        <p className="font-bold text-[1.4rem] md:text-[1.7rem] leading-none lg:text-[2rem]">Instant trading</p>
                        <p className="mb-4 mt-2 lg:text-xl">Convert your crypto to cash and withdraw instantly. </p>
                    </div>

                    <div className="w-full md:w-[32%] bg-[#9696f61c] p-3 rounded-2xl">
                        <FontAwesomeIcon className="text-3xl text-[blue] mt-4 mb-6" icon={faIndustry} />
                        <p className="font-bold text-[1.4rem] md:text-[1.7rem] leading-none lg:text-[2rem]">24/7 support</p>
                        <p className="mb-4 mt-2 lg:text-xl">Never get stucked. Get help round the clock. </p>
                    </div>

                    <div className="w-full md:w-[32%] bg-[#9696f61c] p-3 rounded-2xl">
                        <FontAwesomeIcon className="text-3xl text-[blue] mt-4 mb-6" icon={faLock} />
                        <p className="font-bold text-[1.4rem] md:text-[1.7rem] leading-none lg:text-[2rem]">Secure vault</p>
                        <p className="mb-4 mt-2 lg:text-xl">Military-grade asset vault for maximum security. </p>
                    </div>
                </div>
                <img src={icons} className='mb-3 rounded-3xl' alt='coins' />
            </div>
            <div className="bg-white mt-[4rem] rounded-[2.5rem] gap-y-10 w-11/12 p-4 flex justify-between flex-wrap items-center xl:w-3/4 mx-auto">
                <div className='w-full md:w-[40%]'>
                    <h4 className="text-[1.5rem] mt-[1rem] md:text-[2rem] font-bold lg:text-[2.5rem] leading-none"> <span className="">Join the</span>
                        <span className="block text-[#474FF4] ">CLDX pre-sale</span>
                    </h4>
                    <p className='my-4 md:my-6 lg:text-xl'>Get a chance to purchase the Cloudax Token at the earliest possible opportunity.</p>
                    <a href='https://t.me/cloudaxofficial' className="block w-fit px-9 py-4 bg-icon text-white bg-center bg-cover rounded-3xl">Join Telegram</a>
                </div>
                <img className='w-full md:w-[45%]' src={small} alt='images' />
            </div>
        </div>
    )
}

export default Trusted