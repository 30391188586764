import logo from '../images/assets/Frame 60067.png'
import phone from '../images/assets/phone.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple, faGithub, faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { faPlay, faBars, faXmark, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import logo2 from '../images/assets/logo2.png'
import { useState, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'

const Layout = ({ children }) => {
    const [nav, setNav] = useState(false)
    const [show, setShow] = useState('')
    const year = new Date().getFullYear()
    const navRef = useRef();

    const tab1 = [
        { name: 'About', link: '/about' },
        { name: 'Blog', link: 'http://spotlight.cloudax.io/' },
        { name: 'Career', link: '/' },
        { name: 'Press', link: '/' },
    ]
    const tab2 = [
        { name: 'DEX', link: 'http://swap.cloudax.io/' },
        { name: 'NFT', link: 'http://nft.cloudax.io/' },
        { name: 'Launchpad', link: 'https://launchpad.cloudax.io/' },
    ]
    const tab3 = [
        { name: 'What is crypto ?', link: 'https://academy.cloudax.io/learn/introduction-to-cryptocurrency/' },
        { name: 'How to setup cloudax wallet', link: '/' },
        { name: 'How to secure my crypto', link: 'https://academy.cloudax.io/learn/how-to-secure-your-cryptocurrency/' },
    ]
    const tab4 = [
        { name: 'Get Help', link: 'https://help.cloudax.io/en/collections/3705616-cloudax-wallet' },
        { name: 'Academy', link: 'http://academy.cloudax.io/' },
        { name: 'FAQ', link: '/' },
    ]

    const leaves = () => {
        if (show) {
            setShow('')
        }
    }

    const manager = (item) => {
        if (show) {
            setShow('')
        } else {
            setShow(item)
        }
    }

    const clickers = () => {
        navRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <section className='w-full'>
            <div className=' py-1 w-full bg-curve '>
                <nav ref={navRef} className='flex my-4 items-center overflow-x-clip right-0 justify-between w-11/12 relative  mx-auto '>
                    <Link to='/'><img src={logo} className='w-[8rem]' alt='logo' /></Link>

                    <div className='hidden lg:flex gap-9'>
                        <div onMouseLeave={leaves} onMouseEnter={() => setShow('company')} className='py-3 relative'>
                            <p className='font-semibold flex items-center gap-1'>Company
                                <FontAwesomeIcon icon={faAngleDown} />
                            </p>
                            {show === 'company' && <div className='absolute top-[3rem] left-[-4rem] rounded-3xl bg-white text-center w-[12rem]'>
                                <Link to='/about' className='py-4 hover:text-[blue] block'>About</Link>
                                <a href='http://spotlight.cloudax.io/' className='py-4 block hover:text-[blue]'>Blog</a>
                            </div>}
                        </div>
                        <div onMouseLeave={leaves} onMouseEnter={() => setShow('resources')} className='py-3 relative '>
                            <p className='font-semibold flex items-center gap-1'>Resources
                                <FontAwesomeIcon icon={faAngleDown} />
                            </p>
                            {show === 'resources' && <div className='absolute top-[3rem] left-[-4rem] rounded-3xl bg-white text-center w-[12rem]'>
                                <a href='http://academy.cloudax.io/' className='block py-4 hover:text-[blue]'>Learn</a>
                                <p className='py-4 hover:text-[blue]'>Careers</p>
                            </div>}
                        </div>
                        <a href='http://help.cloudax.io/' className='py-3 hover:cursor-pointer font-semibold '>Support</a>
                    </div>

                    <div className='hidden  lg:flex items center gap-4'>
                        <a href='https://account.cloudax.finance/login' className='hover:border hover:border-black px-5 py-3 rounded-2xl transition-all duration-500'>Login</a>
                        <a href='https://account.cloudax.finance/signup' className='py-3 px-5 border rounded-2xl hover:bg-black hover:text-white transition-all duration-500 border-[#00000043]'>Sign up</a>
                    </div>

                    <button onClick={() => setNav(!nav)} className='lg:hidden mt-1'>
                        <FontAwesomeIcon className={` transition-all duration-500 ${nav ? 'text-3xl' : 'text-xl'}`} icon={nav ? faXmark : faBars} />
                    </button>

                    <AnimatePresence>
                        {nav && <motion.div key='box' initial={{ x: 500 }} animate={{ x: 0 }} exit={{ x: 500 }} transition={{ duration: 0.5 }} className='w-full h-[100vh] absolute top-[3rem] flex flex-col z-10 bg-curve bg-center bg-cover'>
                            <a href='https://account.cloudax.finance/login' className='py-3 border-b border-[#00000039] '>Login</a>
                            <a href='https://account.cloudax.finance/signup' className='py-3 border-b border-[#00000039] ' >Sign Up</a>
                            <div onClick={() => manager('company')} className='cursor-pointer border-b border-[#00000039]' >
                                <p className='flex py-3 items-center gap-1'>Company <FontAwesomeIcon icon={faAngleDown} /></p>
                                {show === 'company' && <div className=''>
                                    <Link to='/about' className='py-2 block'>About</Link>
                                    <a href='http://spotlight.cloudax.io/' className=' block py-3'>Blog</a>
                                </div>}
                            </div>
                            <div onClick={() => manager('resources')} className='cursor-pointer border-b border-[#00000039]'>
                                <p className='flex items-center py-3 gap-1'>Resources <FontAwesomeIcon icon={faAngleDown} /></p>
                                {show === 'resources' && <div className=''>
                                    <a href='http://academy.cloudax.io/' className='block py-3'>Learn</a>
                                    <p className='py-3 block'>Careers</p>
                                </div>}
                            </div>
                            <a href='http://support.cloudax.io/' className='block py-3 border-b border-[#00000039]'>Support</a>
                            <div className='w-full flex text-[1.5rem] grow items-center justify-center gap-5'>
                                <a href='/'> <FontAwesomeIcon icon={faTwitter} /> </a>
                                <a href='https://t.me/cloudaxofficial'> <FontAwesomeIcon icon={faTelegram} /> </a>
                                <a href='http://github.com/cloudaxglobal'> <FontAwesomeIcon icon={faGithub} /> </a>
                            </div>
                        </motion.div>}
                    </AnimatePresence>

                </nav>
                <main> {children} </main>
            </div>

            <footer className='bg-index w-full py-1'>
                <div className='w-full lg:w-[99.5%] mx-auto bg-cover-pic bg-center bg-cover rounded-[3rem] mt-[12rem]'>
                    <div className='flex gap-y-10 w-11/12 items-center flex-wrap justify-between text-white xl:w-3/4 mx-auto'>
                        <div className='w-full md:w-[45%]'>
                            <h4 className="text-[1.8rem] mt-[4rem] md:mt-0 md:text-[2.5rem] font-bold lg:text-[4rem] leading-none"> Get Started
                                <span className="md:block">on Cloudax</span>
                            </h4>
                            <p className='my-4 md:my-6 lg:text-xl'>Invite your friends to Cloudax and earn 0.5 CLDX per invite!</p>
                            <div className='flex flex-wrap gap-6 items-center'>
                                <button className="w-full md:w-fit flex justify-center md:justify-start items-center gap-3 md:px-9 py-4 text-[blue] font-bold bg-white bg-center bg-cover rounded-3xl">
                                    <FontAwesomeIcon icon={faApple} /> App Store</button>
                                <button className="w-full md:w-fit flex justify-center md:justify-start items-center gap-3 md:px-9 py-4 text-[blue] font-bold bg-white bg-center bg-cover rounded-3xl">
                                    <FontAwesomeIcon icon={faPlay} />Play Store</button>
                            </div>
                        </div>
                        <div className='w-full md:w-[45%]'>
                            <img className='md:mt-[-5rem]' src={phone} alt='phone' />
                        </div>
                    </div>
                </div>
                <div className='w-11/12 xl:w-3/4 my-[5rem] mx-auto flex-wrap flex justify-between'>
                    <div className='w-full md:w-fit'>
                        <p className='my-5 font-bold text-2xl'>Company</p>
                        {tab1.map((data, index) => index === 0 ? <Link key={index} className='block my-5 text-xl' to={data.link}>{data.name}</Link> : <a key={index} className='block my-5 text-xl' href={data.link}>{data.name}</a>)}
                    </div>
                    <div className='w-full md:w-fit'>
                        <p className='my-5 font-bold text-2xl'>Learn</p>
                        {tab3.map((data, index) => <a key={index} className='block text-xl my-5' href={data.link}>{data.name}</a>)}
                    </div>
                    <div className='w-full md:w-fit'>
                        <p className='my-5 font-bold text-2xl'>Support</p>
                        {tab4.map((data, index) => <a key={index} className='block text-xl my-5' href={data.link}>{data.name}</a>)}
                    </div>
                    <div className='w-full md:w-fit'>
                        <p className='my-5 font-bold text-2xl '>Products</p>
                        {tab2.map((data, index) => <a key={index} className='block my-5 text-xl' href={data.link}> {data.name}</a>)}
                    </div>
                </div>

                <div className='mx-auto w-full text-center'>
                    <img onClick={clickers} className='mx-auto' src={logo2} alt='logo2' />
                    <div className='flex mt-6 gap-4 items-center mb-[5rem] justify-center flex-wrap'>
                        <div className='w-full md:w-fit  flex text-[1.5rem] items-center justify-center gap-2'>
                            <a href='https://twitter.com/cloudaxHQ'> <FontAwesomeIcon icon={faTwitter} /> </a>
                            <a href='https://t.me/cloudaxofficial'> <FontAwesomeIcon icon={faTelegram} /> </a>
                            <a href='http://github.com/cloudaxglobal'> <FontAwesomeIcon icon={faGithub} /> </a>
                        </div>
                        <Link className=' text-[blue]' to='/terms-of-use'>Terms of use</Link>
                        <Link className='text-[blue]' to='/privacy-policy'>Privacy Policy</Link>
                        {/* <Link className='text-[blue]' to='/ '>Cookies</Link>
                        <Link className='text-[blue]' to='/ '>Explore</Link> */}
                        <p className='whitespace-nowrap'> <FontAwesomeIcon icon={faCopyright} /> {year} Cloudax</p>
                    </div>
                </div>
            </footer>
        </section>
    )
}

export default Layout